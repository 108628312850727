import {
	Box,
	Button,
	Divider,
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	Tab,
	Tabs,
	Typography,
} from "@material-ui/core";
import { Alert, Skeleton } from "@material-ui/lab";
import { useAll } from "@typesaurus/react";
import {
	LinkOutline,
	MailOpenOutline,
	MailOutline,
	MapOutline,
	PhoneOutline,
} from "heroicons-react";
import React, { useMemo, useState } from "react";
import { Navigation } from "react-feather";
import ReactMapboxGl, { Feature, Layer, Marker, Popup } from "react-mapbox-gl";
import { Route, Switch, useHistory } from "react-router-dom";
import { collection } from "typesaurus";

import "mapbox-gl/dist/mapbox-gl.css";

import { NakedLink } from "../../helpers/components/NakedLink";
import { DatabasePlace } from "../../types/firebase/places";
import { Container, MapContainer, Pin, PopupContent, PopupPic } from "./styles";

export interface PlaceListProperties {
	children: JSX.Element;
}

const places = collection<DatabasePlace>("places");

const PlaceList: React.FC<PlaceListProperties> = ({
	children,
}: PlaceListProperties): JSX.Element => {
	const [placesList = [], { loading, error }] = useAll(places);
	const [focusedPlace, setFocusedPlace] = useState<string | undefined>();
	const history = useHistory();

	const Map = useMemo(
		() =>
			ReactMapboxGl({
				accessToken:
					"pk.eyJ1IjoiZWwxZmxlbSIsImEiOiJja2h2NHJteXUwYmxxMnZvMnZnaTk5MTM5In0.EiQAhfCUuMgCNdfX_9YcEg",
			}),
		[],
	);

	return (
		<>
			<Switch>
				<Route path="/places/map">
					<Box display="flex" flexDirection="column">
						<Box display="flex">
							<Box width={16} height={"100%"} />
							<Tabs indicatorColor="primary" textColor="primary" value={1}>
								<NakedLink to="/places/list">
									<Tab label="Lista" />
								</NakedLink>
								<Tab label="Mappa" />
							</Tabs>
							<Divider />
						</Box>
					</Box>
					<MapContainer>
						<Map
							center={
								focusedPlace
									? [
											placesList.find(({ ref }) => ref.id === focusedPlace)
												?.data.coords?.T_,
											placesList.find(({ ref }) => ref.id === focusedPlace)
												?.data.coords?.w_,
									  ]
									: [11.058626, 46.391527]
							}
							zoom={[14]}
							style="mapbox://styles/el1flem/ckhv4nqii01rq19pmm1c3hwe0"
							containerStyle={{
								height: "100%",
								width: "100%",
							}}
							onClick={() => setFocusedPlace(undefined)}
						>
							{placesList.map((place) => {
								const { name, address, coords, gallery } = place.data;

								return (
									<>
										{focusedPlace === place.ref.id && (
											<Popup
												coordinates={[coords?.T_ ?? 0, coords?.w_ ?? 0]}
												anchor="top"
												offset={[-5, 32]}
											>
												<PopupContent>
													{gallery && gallery[0] && (
														<PopupPic src={gallery[0]} />
													)}
													<Typography variant="h5">{name}</Typography>
													<Typography variant="body1">{address}</Typography>
													<Box mt={2}>
														<IconButton
															edge="start"
															onClick={() =>
																window.open(
																	`https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${address}`,
																	"_blank",
																)
															}
														>
															<Navigation />
														</IconButton>
														{place.data.phone && (
															<IconButton
																component="a"
																target="_blank"
																href={`tel:${place.data.phone}`}
															>
																<PhoneOutline />
															</IconButton>
														)}
														{place.data.email && (
															<IconButton
																component="a"
																target="_blank"
																href={`mailto:${place.data.email}`}
															>
																<MailOutline />
															</IconButton>
														)}
														{place.data.website && (
															<IconButton
																component="a"
																target="_blank"
																href={place.data.website}
															>
																<LinkOutline />
															</IconButton>
														)}
													</Box>
													<Box mt={2}>
														<Button
															fullWidth
															variant="outlined"
															color="primary"
															onClick={() =>
																history.push(`/places/${place.ref.id}`)
															}
														>
															Visualizza
														</Button>
													</Box>
												</PopupContent>
											</Popup>
										)}
										<Marker
											key={place.ref.id}
											anchor="bottom"
											coordinates={[coords?.T_ ?? 0, coords?.w_ ?? 0]}
											style={{
												zIndex: focusedPlace === place.ref.id ? 2 : 1,
											}}
										>
											<Pin
												$active={focusedPlace === place.ref.id}
												onClick={() => setFocusedPlace(place.ref.id)}
											/>
										</Marker>
									</>
								);
							})}
						</Map>
					</MapContainer>
				</Route>
				<Route path="/places/list">
					<Box display="flex" flexDirection="column">
						<Box display="flex">
							<Box width={16} height={"100%"} />
							<Tabs indicatorColor="primary" textColor="primary" value={0}>
								<Tab label="Lista" />
								<NakedLink to="/places/map">
									<Tab label="Mappa" />
								</NakedLink>
							</Tabs>
						</Box>
						<Divider />
					</Box>
					<Container>
						<Typography variant="h2">Punti di interesse</Typography>
						{loading && (
							<List disablePadding>
								<ListItem>
									<ListItemText
										primary={<Skeleton width="85%" />}
										secondary={<Skeleton width="50%" />}
									/>
								</ListItem>
								<ListItem>
									<ListItemText
										primary={<Skeleton width="55%" />}
										secondary={<Skeleton width="65%" />}
									/>
								</ListItem>
								<ListItem>
									<ListItemText
										primary={<Skeleton width="75%" />}
										secondary={<Skeleton width="45%" />}
									/>
								</ListItem>
							</List>
						)}
						{loading || (
							<List disablePadding>
								{error && (
									<Alert severity="error">
										Impossibile caricare i contatti
									</Alert>
								)}
								{placesList.map((place) => {
									const { name, address, phone, email } = place.data;

									return (
										<ListItem
											key={place.ref.id}
											component="a"
											button
											onClick={() => history.push(`/places/${place.ref.id}`)}
										>
											<ListItemText primary={name} secondary={address} />
											<ListItemSecondaryAction>
												<IconButton
													edge="end"
													onClick={() =>
														window.open(
															`https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${address}`,
															"_blank",
														)
													}
												>
													<Navigation />
												</IconButton>
											</ListItemSecondaryAction>
										</ListItem>
									);
								})}
							</List>
						)}
						{children}
					</Container>
				</Route>
			</Switch>
		</>
	);
};

export default PlaceList;
