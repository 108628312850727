import {
	Box,
	ButtonBase,
	Container as MuiContainer,
	Grid,
	Icon,
	IconButton,
	LinearProgress,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Toolbar,
	Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useGet } from "@typesaurus/react";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { Delete, Edit, FileText, Trash, X } from "react-feather";
import {
	HiGlobe,
	HiLocationMarker,
	HiOutlineDocument,
	HiOutlineGlobe,
	HiOutlineGlobeAlt,
	HiOutlineLocationMarker,
	HiOutlineMail,
	HiOutlinePhone,
	HiOutlineTag,
} from "react-icons/hi";
import { Link, useHistory, useParams } from "react-router-dom";
import { collection } from "typesaurus";

import { firestore, storage } from "../../config/firebase";
import { DatabasePlace } from "../../types/firebase/places";
import { AttachmentBox, Container, Gallery, Info, Picture } from "./styles";

const places = collection<DatabasePlace>("places");

const Place: React.FC = () => {
	const history = useHistory();
	const { placeId } = useParams<{ placeId: string }>();
	const [place, { loading, error }] = useGet(places, placeId);
	const [currentPhoto, setCurrentPhoto] = useState(0);

	useEffect(() => {
		setCurrentPhoto(0);
	}, [place?.data.gallery]);

	return (
		<Container>
			{place && (
				<MuiContainer>
					<Info>
						<Typography>
							{/* {format(place.data.publishingDate, "dd MMM yyyy")} */}
						</Typography>
					</Info>
					<Box height={34} />
					<Typography variant="h3">{place.data.name}</Typography>
					<Grid container spacing={3}>
						{place.data?.gallery && (
							<Grid item xs={12} md={6}>
								{place.data?.gallery[currentPhoto] && (
									<Picture
										src={
											(currentPhoto !== undefined &&
												place.data?.gallery?.[currentPhoto] &&
												place.data?.gallery?.[currentPhoto]) ||
											""
										}
										alt=""
									/>
								)}
								<Gallery>
									{place.data.gallery?.map((photo, index) => (
										<ButtonBase
											key={photo}
											onClick={() => setCurrentPhoto(index)}
										>
											<img src={photo} alt="" />
										</ButtonBase>
									))}
								</Gallery>
							</Grid>
						)}
						<Grid item>
							<List>
								<ListItem disableGutters>
									<ListItemIcon>
										<Icon>
											<HiOutlineLocationMarker />
										</Icon>
									</ListItemIcon>
									<ListItemText primary={place.data.address} />
								</ListItem>
								{place.data.email && (
									<ListItem
										disableGutters
										button
										component="a"
										href={`mailto:${place.data.email}`}
										target="_blank"
									>
										<ListItemIcon>
											<Icon>
												<HiOutlineMail />
											</Icon>
										</ListItemIcon>
										<ListItemText primary={place.data.email} />
									</ListItem>
								)}
								{place.data.phone && (
									<ListItem
										disableGutters
										button
										component="a"
										href={`tel:${place.data.phone}`}
										target="_blank"
									>
										<ListItemIcon>
											<Icon>
												<HiOutlinePhone />
											</Icon>
										</ListItemIcon>
										<ListItemText primary={place.data.phone} />
									</ListItem>
								)}
								{place.data.website && (
									<ListItem
										disableGutters
										button
										component="a"
										href={
											place.data.website?.includes("https://")
												? place.data.website
												: `https://${place.data.website}`
										}
										target="_blank"
									>
										<ListItemIcon>
											<Icon>
												<HiOutlineGlobeAlt />
											</Icon>
										</ListItemIcon>
										<ListItemText primary={place.data.website} />
									</ListItem>
								)}
							</List>
							{place.data.notes && (
								<Box mt={2}>
									<Typography variant="h5">Dettagli</Typography>
									<Typography>{place.data.notes}</Typography>
								</Box>
							)}
						</Grid>
					</Grid>
				</MuiContainer>
			)}
		</Container>
	);
};

export default Place;
