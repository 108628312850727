import { Container as MuiContainer } from "@material-ui/core";
import styled from "styled-components/macro";

const Container = styled(MuiContainer)`
	& > .MuiTypography-root {
		margin-bottom: ${({ theme }) => theme.spacing(1)}px;
	}

	padding-top: ${({ theme }) => theme.spacing(3)}px;

	.Mui {
		&List {
			&-root {
				margin: ${({ theme }) => theme.spacing(0, -2)};
			}
		}

		&ListItem {
			&-root {
				color: inherit;
			}

			&-indicator {
				display: none;
			}
		}
	}
`;

export const MapContainer = styled.div`
	height: calc(100vh - 160px);
	width: 100%;
`;

export const Pin = styled.div<{
	$active: boolean;
}>`
	animation-duration: 1s;
	animation-fill-mode: both;
	animation-name: bounce;
	background: ${({ theme, $active }) =>
		$active ? theme.palette.primary.main : theme.palette.primary.main};
	border-radius: 50% 50% 50% 0;
	height: 30px;
	left: 50%;
	margin: -20px 0 0 -20px;
	position: absolute;
	position: relative;
	top: 50%;
	transform: rotate(-45deg) scale(${({ $active }) => ($active ? 1.35 : 1)});
	transition: 0.2s;
	width: 30px;
	z-index: ${({ $active }) => ($active ? 2 : 1)};

	&::after {
		background: ${({ theme, $active }) =>
			$active
				? theme.palette.background.paper
				: theme.palette.background.paper};
		border-radius: 50%;
		content: "";
		height: 22px;
		margin: 4px 0 0 4px;
		position: absolute;
		width: 22px;
	}
`;

export const PopupContent = styled.div`
	max-width: 100%;
	padding: ${({ theme }) => theme.spacing(1)}px;
	width: 256px;
`;

export const PopupPic = styled.img`
	height: 160px;
	margin: -18px;
	margin-bottom: 8px;
	object-fit: cover;
	width: calc(100% + 36px);
`;

export { Container };
