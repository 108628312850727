import {
	Box,
	Button,
	FormControlLabel,
	List,
	ListItem,
	Switch,
	Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useSnackbar } from "notistack";
import React, { ChangeEvent, useEffect, useState } from "react";

import {
	enableNotifications,
	functions,
	messaging,
} from "../../config/firebase";
import { Container } from "./styles";

function useForceUpdate() {
	const [value, setValue] = useState(0); // integer state
	return () => setValue((value) => value + 1); // update the state to force render
}

const Settings: React.FC = () => {
	const forceUpdate = useForceUpdate();

	const categories = [
		"avvisi",
		"allerte",
		"eventi",
		"ordinanze",
		"novità",
	] as const;

	const { enqueueSnackbar } = useSnackbar();

	const [subscribedTopics, setSubscribedTopics] = useState<string[]>([
		"allerte",
	]);

	useEffect(() => {
		const notificationPreferences = JSON.parse(
			localStorage.getItem("notificationPreferences") || `["allerte"]`,
		);
		setSubscribedTopics(notificationPreferences);
	}, []);

	const handleNotificationsUpdate = () => {
		if (!messaging) return;

		messaging
			.getToken({
				vapidKey:
					"BIp0oVotwIJ-Wi9ZtYA4XuktdsbDCmP5woSE_szvgqILj36kkrVnpVNMIMfKHwyvnNot0RaFdTTj-xeBnKc-Wkw",
			})
			.then((currentToken) => {
				if (currentToken) {
					const registerUserToken = functions.httpsCallable(
						"registerUserToken",
					);
					registerUserToken({
						token: currentToken || "",
						topics: subscribedTopics,
					});
					localStorage.setItem(
						"notificationPreferences",
						JSON.stringify(subscribedTopics),
					);
					enqueueSnackbar("Preferenze salvate");
				} else {
					Notification.requestPermission();
					console.log(
						"No registration token available. Request permission to generate one.",
					);
				}
			})
			.catch((error) => {
				console.log("An error occurred while retrieving token.", error);
			});
	};

	return (
		<Container>
			<Typography variant="h2">Impostazioni</Typography>
			<Box mt={3}>
				<Typography variant="h5">Notifiche</Typography>
				<Typography>
					Imposta le categorie di notizie per cui ricevere le notifiche push.
				</Typography>
				<Box mt={2} mb={1}>
					{Notification.permission !== "granted" && (
						<Alert severity="info">
							<Box mb={2}>
								Per modificare le impostazioni delle notifiche devi prima
								abilitarle!
							</Box>
							<Button
								variant="outlined"
								onClick={() => {
									Notification.requestPermission().then(() => {
										enableNotifications();
										forceUpdate();
									});
								}}
							>
								Abilita notifiche
							</Button>
						</Alert>
					)}
				</Box>
				<List>
					{categories
						.filter((category) => category !== "allerte")
						.map((category) => (
							<ListItem key={category} disableGutters>
								<FormControlLabel
									control={
										<Switch
											color="primary"
											disabled={Notification.permission !== "granted"}
											onChange={() => {
												if (subscribedTopics.includes(category))
													setSubscribedTopics(
														subscribedTopics.filter(
															(topic) => topic !== category,
														),
													);
												else
													setSubscribedTopics([...subscribedTopics, category]);
											}}
											checked={subscribedTopics.includes(category)}
										/>
									}
									label={category}
								/>
							</ListItem>
						))}
				</List>
				<Button
					variant="contained"
					color="primary"
					onClick={handleNotificationsUpdate}
				>
					Salva preferenze
				</Button>
			</Box>
		</Container>
	);
};

export default Settings;
