import {
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	Typography,
} from "@material-ui/core";
import { Alert, Skeleton } from "@material-ui/lab";
import { useAll } from "@typesaurus/react";
import React from "react";
import { Mail, Phone } from "react-feather";
import { Link } from "react-router-dom";
import { collection } from "typesaurus";

import { NakedLink } from "../../helpers/components/NakedLink";
import { DatabaseContact } from "../../types/firebase/contacts";
import { Container } from "./styles";

export interface ContactListProperties {
	children: JSX.Element;
}

const contacts = collection<DatabaseContact>("contacts");

const ContactList: React.FC<ContactListProperties> = ({
	children,
}: ContactListProperties): JSX.Element => {
	const [contactsList = [], { loading, error }] = useAll(contacts);

	return (
		<Container>
			<Typography variant="h2">Contatti Utili</Typography>
			{loading && (
				<List disablePadding>
					<ListItem>
						<ListItemText
							primary={<Skeleton width="85%" />}
							secondary={<Skeleton width="50%" />}
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary={<Skeleton width="55%" />}
							secondary={<Skeleton width="65%" />}
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary={<Skeleton width="75%" />}
							secondary={<Skeleton width="45%" />}
						/>
					</ListItem>
				</List>
			)}
			{loading || (
				<List disablePadding>
					{error && (
						<Alert severity="error">Impossibile caricare i contatti</Alert>
					)}
					{contactsList
						.sort((a, b) => a.data.label.localeCompare(b.data.label))
						.map((contact) => {
							const { label, phone, email } = contact.data;

							return (
								<ListItem key={contact.ref.id}>
									<ListItemText
										primary={label}
										secondary={`${phone ?? ""}${phone && email ? " • " : ""}${
											email ?? ""
										}`}
									/>
									<ListItemSecondaryAction>
										<IconButton
											edge="end"
											onClick={() =>
												window.location.replace(
													phone ? `tel:${phone}` : `mailto:${email}`,
												)
											}
										>
											{phone && <Phone />}
											{email && !phone && <Mail />}
										</IconButton>
									</ListItemSecondaryAction>
								</ListItem>
							);
						})}
				</List>
			)}
			{children}
		</Container>
	);
};

export default ContactList;
