import {
	Button,
	List,
	Tab,
	Tabs,
	Typography,
	useMediaQuery,
	useTheme,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useOnQuery } from "@typesaurus/react";
import { compareDesc } from "date-fns";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { collection, where } from "typesaurus";

import ArticleBrief, {
	ArticleBriefSkeleton,
} from "../../components/ArticleBrief";
import { remoteConfig } from "../../config/firebase";
import { NakedLink } from "../../helpers/components/NakedLink";
import { DatabaseArticle } from "../../types/firebase/articles";
import { Container, Cover } from "./styles";

export interface ArticleListProperties {
	children: JSX.Element;
}

const categories = [
	"tutte",
	"avvisi",
	"allerte",
	"giovani e famiglie",
	"ordinanze",
	"eventi",
] as const;

const articles = collection<DatabaseArticle>("articles");

const ArticleList: React.FC<ArticleListProperties> = ({
	children,
}: ArticleListProperties): JSX.Element => {
	const history = useHistory();
	const [selectedCategory, setSelectedCategory] = useState(0);
	const [articlesList, { loading, error }] = useOnQuery(
		articles,
		selectedCategory > 0
			? [where("categories", "array-contains", categories[selectedCategory])]
			: [],
	);
	const theme = useTheme();
	const isMedium = useMediaQuery(theme.breakpoints.up("md"));

	return (
		<>
			{remoteConfig.getBoolean("shouldShowNewsCover") && (
				<Cover>
					{remoteConfig.getString("newsCover") && (
						<img src={remoteConfig.getString("newsCover")} alt="" />
					)}
					<Typography variant={isMedium ? "h1" : "h2"}>
						Bentornato a Novella
					</Typography>
				</Cover>
			)}
			<Container>
				<Tabs
					value={selectedCategory}
					onChange={(_, value) => setSelectedCategory(value)}
					variant="scrollable"
					scrollButtons="auto"
				>
					{categories.map((category) => (
						<Tab key={category} label={category} />
					))}
				</Tabs>
				{loading && (
					<List disablePadding>
						<ArticleBriefSkeleton />
						<ArticleBriefSkeleton hasPicture />
						<ArticleBriefSkeleton />
					</List>
				)}
				{loading || (
					<List disablePadding>
						{error && (
							<Alert severity="error">Impossibile caricare gli articoli</Alert>
						)}
						{selectedCategory === 4 && (
							<a
								href="https://www.albotelematico.tn.it/bacheca/novella"
								target="_blank"
								rel="noreferrer"
							>
								<Alert severity="info">
									L&apos;albo e&apos; disponibile cliccando qui:
								</Alert>
							</a>
						)}
						{articlesList
							?.sort((a, b) =>
								compareDesc(a.data.publishingDate, b.data.publishingDate),
							)
							.filter((article) => article.data.isPublished)
							.filter((article) =>
								article.data.title
									.toLowerCase()
									.includes(
										new URLSearchParams(location.search)
											.get("query")
											?.toLocaleLowerCase() ?? "",
									),
							)
							.map((article) => (
								<NakedLink
									key={article.ref.id}
									to={`/articles/${article.ref.id}`}
								>
									<ArticleBrief
										picture={article.data.mainPicture}
										categories={article.data.categories}
										title={article.data.title}
										date={article.data.publishingDate}
										description={article.data.plainTextBody}
										gravity={article.data.gravity}
									/>
								</NakedLink>
							))}
					</List>
				)}
				{children}
			</Container>
		</>
	);
};

export default ArticleList;
