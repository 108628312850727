import firebaseCore from "firebase/app";

import "firebase/firestore";
import "firebase/auth";
import "firebase/analytics";
import "firebase/performance";
import "firebase/storage";
import "firebase/messaging";
import "firebase/functions";
import "firebase/remote-config";

export const firebaseConfig = {
	apiKey: "AIzaSyCRnOxK-VTZYxHQzTcdTio3WQ-gcCxHn00",
	authDomain: "el1flem-bard-novella.firebaseapp.com",
	projectId: "el1flem-bard-novella",
	storageBucket: "el1flem-bard-novella.appspot.com",
	messagingSenderId: "1020208748171",
	appId: "1:1020208748171:web:d5a70a4b6867d7cb28668e",
};

const remoteConfigDefaultState = {
	shouldShowNewsCover: "true",
	novitàCover: "",
};

if (firebaseCore.apps.length === 0) {
	firebaseCore.initializeApp(firebaseConfig);
}

export const firebase = firebaseCore;
export const firestore = firebaseCore.firestore();
export const storage = firebaseCore.storage();
export const auth = firebase.auth();
export const functions = firebase.functions();
export const remoteConfig = firebase.remoteConfig();
export const analytics = firebase.analytics();
export const messaging = firebase.messaging.isSupported()
	? firebase.messaging()
	: undefined;

if (!Boolean(auth.currentUser)) {
	firebase.auth().signInAnonymously();
}

remoteConfig.settings = {
	fetchTimeoutMillis: 1800000,
	minimumFetchIntervalMillis: 3600000,
};

remoteConfig.defaultConfig = remoteConfigDefaultState;

remoteConfig.fetchAndActivate();

export const enableNotifications = (): void => {
	if (messaging) {
		messaging.getToken({
			vapidKey:
				"BIp0oVotwIJ-Wi9ZtYA4XuktdsbDCmP5woSE_szvgqILj36kkrVnpVNMIMfKHwyvnNot0RaFdTTj-xeBnKc-Wkw",
		});

		messaging
			.getToken({
				vapidKey:
					"BIp0oVotwIJ-Wi9ZtYA4XuktdsbDCmP5woSE_szvgqILj36kkrVnpVNMIMfKHwyvnNot0RaFdTTj-xeBnKc-Wkw",
			})
			.then((currentToken) => {
				if (currentToken) {
					const registerUserToken = functions.httpsCallable(
						"registerUserToken",
					);
					registerUserToken({
						token: currentToken || "",
						topics: ["allerte"],
					});
					// sendTokenToServer(currentToken);
					// updateUIForPushEnabled(currentToken);
				} else {
					Notification.requestPermission();
					// Show permission request.
					console.log(
						"No registration token available. Request permission to generate one.",
					);
					// Show permission UI.
					// updateUIForPushPermissionRequired();
					// setTokenSentToServer(false);
				}
			})
			.catch((error) => {
				console.log("An error occurred while retrieving token.", error);
				// showToken("Error retrieving registration token. ", error);
				// setTokenSentToServer(false);
			});
	}
};

enableNotifications();
