import {
	Box,
	IconButton,
	LinearProgress,
	Toolbar,
	Typography,
} from "@material-ui/core";
import { Alert, Skeleton } from "@material-ui/lab";
import { useGet } from "@typesaurus/react";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { Edit, FileText, X } from "react-feather";
import Linkify from "react-linkify";
import { Link, useLocation, useParams } from "react-router-dom";
import { collection } from "typesaurus";

import { analytics, storage } from "../../config/firebase";
import { DatabaseArticle } from "../../types/firebase/articles";
import { AttachmentBox, Container, Info, Picture } from "./styles";

const articles = collection<DatabaseArticle>("articles");

const Article: React.FC = () => {
	const loc = useLocation();
	const { articleId } = useParams<{ articleId: string }>();
	const [article, { error }] = useGet(articles, articleId);
	const [attachments, setAttachments] = useState<any>();

	useEffect(() => {
		if (!article || !article.data) return;

		analytics.logEvent("page_view", {
			page_title: article.data.title,
			page_location: articleId,
			page_path: loc.pathname,
		});
	}, [article, articleId, loc.pathname]);

	useEffect(() => {
		const downloadFiles = async () => {
			if (!article) return;
			if (!article.data.attachments || article.data.attachments.length === 0)
				return;
			const detailedAttachments = await Promise.all(
				article.data.attachments.map(async (attachment) => {
					const attachmentReference = await storage.refFromURL(
						attachment?.src?.replace(
							"el1flem-bard.appspot.com/",
							"el1flem-bard-novella.appspot.com/",
						),
					);
					const attachmentMeta = await attachmentReference.getMetadata();
					const attachmentDownloadUrl = await attachmentReference.getDownloadURL();

					return {
						meta: attachmentMeta,
						downloadUrl: attachmentDownloadUrl,
					};
				}),
			);

			setAttachments(detailedAttachments);
		};

		downloadFiles();
	}, [article]);

	if (article) {
		const { publishingDate, title, mainPicture, plainTextBody } = article.data;
		return (
			<Container>
				<Info>
					<Typography>{format(publishingDate, "dd MMM yyyy")}</Typography>
				</Info>
				<Typography variant="h3">{title}</Typography>
				{mainPicture && <Picture src={mainPicture} alt="" />}
				<Typography>
					<Linkify>{plainTextBody}</Linkify>
				</Typography>
				{attachments && (
					<Box mt={3}>
						<Typography variant="h5">Allegati</Typography>
						{attachments.map((attachment: any) => {
							return (
								<AttachmentBox key={attachment.downloadUrl}>
									<a
										href={attachment.downloadUrl}
										download={attachment.meta.name}
										target="_blank"
										rel="noreferrer"
									>
										<FileText />
										<div>
											<Typography>{attachment.meta.name}</Typography>
											<Typography>{attachment.meta.size}</Typography>
										</div>
									</a>
								</AttachmentBox>
							);
						})}
					</Box>
				)}
			</Container>
		);
	}

	return (
		<Container>
			<Typography>
				<Skeleton />
			</Typography>
			<Skeleton variant="rect" height={256} />
			<Typography variant="h1">
				<Skeleton />
			</Typography>
			<Typography>
				<Skeleton />
			</Typography>
		</Container>
	);
};

export default Article;
