import {
	AppBar,
	BottomNavigation,
	BottomNavigationAction,
	Box,
	ClickAwayListener,
	Icon,
	IconButton,
	TextField,
	Toolbar,
	Typography,
	useMediaQuery,
	useTheme,
} from "@material-ui/core";
import { Cog, LocationMarker, Newspaper, Phone } from "heroicons-react";
import React, { ChangeEvent, useState } from "react";
import {
	Bookmark,
	ChevronLeft,
	Heart,
	MapPin,
	Search,
	Settings,
	Share,
	TrendingUp,
	Users,
} from "react-feather";
import { Link, Route, Switch, useHistory, useLocation } from "react-router-dom";

import logo from "../../assets/icons/logo.png";
import {
	BottomNav,
	Container,
	DesktopContainer,
	Sidebar,
	SidebarLink,
} from "./styles";

interface AppLayout {
	children: JSX.Element | JSX.Element[];
}

const AppLayout: React.FC<AppLayout> = ({ children }: AppLayout) => {
	const location = useLocation();
	const history = useHistory();
	const currentRoot = location.pathname.split("/")[1] || "";
	const [isSearching, setIsSearching] = useState(false);
	const theme = useTheme();
	const isMedium = useMediaQuery(theme.breakpoints.up("md"));

	if (isMedium)
		return (
			<Container>
				<AppBar color="transparent" elevation={0} position="sticky">
					<Toolbar>
						<Route exact path="/settings">
							<img src={logo} alt="logo" />
						</Route>
						<Route exact path="/contacts">
							<img src={logo} alt="logo" />
						</Route>
						<Switch>
							<Route exact path="/places">
								<img src={logo} alt="logo" />
							</Route>
							<Route exact path="/places/list">
								<img src={logo} alt="logo" />
							</Route>
							<Route exact path="/places/map">
								<img src={logo} alt="logo" />
							</Route>
							<Route path="/places/:placeId">
								<IconButton
									color="primary"
									edge="start"
									component={Link}
									to="/places"
								>
									<ChevronLeft />
								</IconButton>
							</Route>
						</Switch>
						<Route exact path="/articles">
							<img src={logo} alt="logo" />
							<Box flexGrow={isSearching ? 0 : 1} />
							<ClickAwayListener onClickAway={() => setIsSearching(false)}>
								{isSearching ? (
									<TextField
										autoFocus
										placeholder="Cosa cerchi?"
										variant="outlined"
										defaultValue={new URLSearchParams(location.search).get(
											"query",
										)}
										onChange={(event: ChangeEvent<HTMLInputElement>) =>
											history.push({
												search: event.target.value
													? `?query=${event.target.value}`
													: "",
											})
										}
									/>
								) : (
									<IconButton
										edge="end"
										color="primary"
										onClick={() => setIsSearching(true)}
									>
										<Search />
									</IconButton>
								)}
							</ClickAwayListener>
						</Route>
						<Route path="/articles/:articleId">
							<IconButton
								color="primary"
								edge="start"
								component={Link}
								to="/articles"
							>
								<ChevronLeft />
							</IconButton>
							<Box flexGrow={1} />
							{navigator.share && (
								<IconButton
									color="primary"
									onClick={() => navigator.share({ url: window.location.href })}
								>
									<Share />
								</IconButton>
							)}
							{/* <IconButton edge="end" color="primary">
								<Bookmark />
							</IconButton> */}
						</Route>
					</Toolbar>
				</AppBar>
				<DesktopContainer>
					<Sidebar>
						<SidebarLink to="/articles" $active={currentRoot === "articles"}>
							<Newspaper />
							<Typography>Notizie</Typography>
						</SidebarLink>
						<SidebarLink to="/contacts" $active={currentRoot === "contacts"}>
							<Phone />
							<Typography>Contatti</Typography>
						</SidebarLink>
						<SidebarLink to="/places" $active={currentRoot === "places"}>
							<LocationMarker />
							<Typography>POI</Typography>
						</SidebarLink>
						<SidebarLink to="/settings" $active={currentRoot === "settings"}>
							<Cog />
							<Typography>Impostazioni</Typography>
						</SidebarLink>
					</Sidebar>
					<main>{children}</main>
				</DesktopContainer>
			</Container>
		);

	return (
		<Container>
			<AppBar color="transparent" elevation={0} position="sticky">
				<Toolbar>
					<Route exact path="/settings">
						<img src={logo} alt="logo" />
					</Route>
					<Route exact path="/contacts">
						<img src={logo} alt="logo" />
					</Route>
					<Switch>
						<Route exact path="/places">
							<img src={logo} alt="logo" />
						</Route>
						<Route exact path="/places/list">
							<img src={logo} alt="logo" />
						</Route>
						<Route exact path="/places/map">
							<img src={logo} alt="logo" />
						</Route>
						<Route path="/places/:placeId">
							<IconButton
								color="primary"
								edge="start"
								component={Link}
								to="/places"
							>
								<ChevronLeft />
							</IconButton>
						</Route>
					</Switch>
					<Route exact path="/articles">
						<img src={logo} alt="logo" />
						<Box flexGrow={isSearching ? 0 : 1} />
						<ClickAwayListener onClickAway={() => setIsSearching(false)}>
							{isSearching ? (
								<TextField
									autoFocus
									placeholder="Cosa cerchi?"
									variant="outlined"
									defaultValue={new URLSearchParams(location.search).get(
										"query",
									)}
									onChange={(event: ChangeEvent<HTMLInputElement>) =>
										history.push({
											search: event.target.value
												? `?query=${event.target.value}`
												: "",
										})
									}
								/>
							) : (
								<IconButton
									edge="end"
									color="primary"
									onClick={() => setIsSearching(true)}
								>
									<Search />
								</IconButton>
							)}
						</ClickAwayListener>
					</Route>
					<Route path="/articles/:articleId">
						<IconButton
							color="primary"
							edge="start"
							component={Link}
							to="/articles"
						>
							<ChevronLeft />
						</IconButton>
						<Box flexGrow={1} />
						{navigator.share && (
							<IconButton
								color="primary"
								onClick={() => navigator.share({ url: window.location.href })}
							>
								<Share />
							</IconButton>
						)}
						<IconButton edge="end" color="primary">
							<Bookmark />
						</IconButton>
					</Route>
				</Toolbar>
			</AppBar>
			{children}
			<Box height={80} />
			<BottomNav value={currentRoot || ""}>
				<BottomNavigationAction
					icon={<Newspaper />}
					label="Notizie"
					component={Link}
					value="articles"
					to="/articles"
				/>
				<BottomNavigationAction
					icon={<Phone />}
					label="Contatti"
					component={Link}
					value="contacts"
					to="/contacts"
				/>
				<BottomNavigationAction
					icon={<LocationMarker />}
					label="POI"
					component={Link}
					value="places"
					to="/places"
				/>
				<BottomNavigationAction
					icon={<Cog />}
					label="Impostazioni"
					component={Link}
					value="settings"
					to="/settings"
				/>
			</BottomNav>
		</Container>
	);
};

export default AppLayout;
